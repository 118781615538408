$('.expandable-sections li button').click((e) => {
    e.preventDefault();

    const $target = $(e.target);
    const $li = $target.parents('li');
    const isOpen = $li.hasClass('open');

    $target.parents('ul').find('li').removeClass('open');

    if (!isOpen) {
        $li.addClass('open');
    }
});
