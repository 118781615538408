const animationDuration = 2000;
const frameDuration = 1000 / 60;
const totalFrames = Math.round( animationDuration / frameDuration );
const easeOutQuad = t => t * ( 2 - t );

// The animation function, which takes an Element
const animateCountUp = el => {
    let frame = 0;
    const countTo = parseInt( el.innerHTML, 10 );
    // Start the animation running 60 times per second
    const counter = setInterval( () => {
        frame++;

        const progress = easeOutQuad( frame / totalFrames );
        const currentCount = Math.round( countTo * progress );

        if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
            el.innerHTML = currentCount;
        }

        if ( frame === totalFrames ) {
            clearInterval( counter );
        }
    }, frameDuration );
};

const runAnimations = () => {
    const countupEls = document.querySelectorAll( '.number-int' );
    countupEls.forEach( animateCountUp );
};

let tick = 0;
const animateVisible = (block, ratio, isIntersecting) => {
    // When the element is in view:
    if (ratio > 0 && isIntersecting && tick <= 0 && block.querySelector( '.number-int')) {
        if(block.querySelectorAll('.number')){
            tick = 1;
            runAnimations();
        }
    }
}


const blocks = document.querySelectorAll(' #content-section section');
const blocksObserver = new IntersectionObserver(
    entries => {
        // entries === elements
        return entries.forEach(event => {
            const {target, intersectionRatio, isIntersecting} = event;
            // Call function
            animateVisible(target, intersectionRatio, isIntersecting);
        });
    },
    //trigger only when above threshold
    {threshold: 0.2}
);
for (const block of blocks) {
    blocksObserver.observe(block);
}