import moment from 'moment'

const performanceEl = document.getElementById('performance');
const incidentCountEl = document.getElementById('incidents');
const timeNowElement = document.getElementById('time-now');

if (performanceEl && incidentCountEl) {
    const performanceSpan = performanceEl.getElementsByTagName('span')[0];
    const incidentCountSpan = incidentCountEl.getElementsByTagName('span')[0];

    const performanceMax = 20;
    const performanceMin = 1;
    const incidentCountMax = 6;
    const incidentCountMin = 1;
    const refreshInterval = 100;

    const movingTime = 1;
    const holdTime = 3;

    const randomIncidentCount = () => {
        return Math.round(Math.random() * (incidentCountMax - incidentCountMin) + incidentCountMin);
    }

    const randomPerformanceValue = (incidentCount) => {
        let min = 5;
        let max = 10;
        switch (incidentCount) {
            case 1:
            case 2:
                min = 5;
                max = 10;
                break;
            case 3:
            case 4:
                min = 10;
                max = 15;
                break
            case 5:
            case 6:
                min = 15;
                max = 20;
                break;
        }

        return Math.round(Math.random() * (max - min) + min);
    }

    let startTime = Date.now() - movingTime;
    let targetTime = Date.now() + movingTime;

    let previousIncidentCount = 5;
    let currentIncidentCount = 5;

    let previousPerformanceValue = 18;
    let currentPerformanceValue = 18;

    const STATE_MOVE = 0;
    const STATE_HOLD = 1;

    let stateMachine = STATE_HOLD;

    const updatePerformance = () => {
        const now = Date.now();
        let timeDiff = (now - startTime) / 1000;

        switch (stateMachine) {
            case STATE_MOVE:
                if (timeDiff >= movingTime) {
                    // Move to HOLD state
                    stateMachine = STATE_HOLD;

                    // Timediff should be equal to moving time (means no more animation while holding)
                    timeDiff = movingTime;
                }
                break;
            case STATE_HOLD:
                if (timeDiff > movingTime + holdTime) {
                    // Move to MOVE state
                    stateMachine = STATE_MOVE;

                    // Reset timestamps and find a new value to move to
                    startTime = Date.now();
                    targetTime = Date.now() + movingTime;
                    previousIncidentCount = currentIncidentCount;
                    currentIncidentCount = randomIncidentCount();
                    previousPerformanceValue = currentPerformanceValue;
                    currentPerformanceValue = randomPerformanceValue(currentIncidentCount);
                }
                return;
        }

        const performanceValue = previousPerformanceValue + ((currentPerformanceValue - previousPerformanceValue) * (timeDiff / movingTime));
        const performanceDisplayValue = Math.round(performanceValue);
        performanceSpan.innerHTML = `– ${performanceDisplayValue} %`;

        //const incidentCount = previousIncidentCount + ((currentIncidentCount - previousIncidentCount) * (timeDiff / movingTime));
        incidentCountSpan.innerHTML = `${currentIncidentCount}`;
    }

    setInterval(updatePerformance, refreshInterval)
}

if (timeNowElement) {
    const updateTimestamp = () => {
        const now = moment();
        timeNowElement.innerHTML = now.format('DD.MM.YYYY HH:mm:ss');
    }

    updateTimestamp();
    setInterval(updateTimestamp, 1000);
}
