let activeFilter = 'published';
let desc = true;

$('.open-positions .filter button').click(function(e) {
    e.preventDefault();

    const $this = $(this);
    const isActive = $this.hasClass('active');
    const isDesc = $this.hasClass('desc');

    $this.closest('.filter').find('button').removeClass('active desc asc');
    $this.addClass('active');

    activeFilter = $this.data('filter');

    if (isActive) {
        if (!isDesc) {
            $this.addClass('desc');
        }

        desc = !desc;
    } else {
        desc = activeFilter === 'published'; // Sort by published date desc by default
    }

    sortPositions();
});

function sortPositions() {
    const $ul = $('.open-positions .positions').find('ul');

    let $positions = $ul.children('li');
    const sortList = Array.prototype.sort.bind($positions);

    sortList(function ( a, b ) {

        // Cache inner content from the first element (a) and the next sibling (b)
        var aText = a.getAttribute('data-' + activeFilter);
        var bText = b.getAttribute('data-' + activeFilter);

        // Returning -1 will place element `a` before element `b`
        if ( aText < bText ) {
            return desc ? 1 : -1;
        }

        // Returning 1 will do the opposite
        if ( aText > bText ) {
            return desc ? -1 : 1;
        }

        // Returning 0 leaves them as-is
        return 0;
    });

    $ul.html('');
    $ul.append($positions);
}


sortPositions();
