let acc = document.getElementsByClassName("list-toggle");
var i;
if(acc) {
    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function () {
            console.log('active')
            /* Toggle between adding and removing the "active" class,
            to highlight the button that controls the panel */
            $(this).parent().toggleClass("active");

            /* Toggle between hiding and showing the active panel */
            var panel = this.nextElementSibling;
            panel.classList.toggle("active");
        });
    }
}
