$('.grouped-expandable-sections ul.groups button').click(e => {
    e.preventDefault();

    const $this = $(e.target);
    const $li = $this.closest('li');
    const isOpen = $li.hasClass('open');

    $this.closest('ul').find('li').removeClass('open');

    if (isOpen) {
        $li.removeClass('open');
    } else {
        $li.addClass('open');
    }
})
