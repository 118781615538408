const $insights_articles = $(".insights .posts-tease .tease");
const $insights_options = $(".insights a.option");

$insights_options.click(function(e) {
    e.preventDefault();
    const $this = $(this);
    const $category = $this.find("p").html().toLowerCase();

    $(".insights a.option.active").removeClass("active");
    $this.addClass("active");

    if($category === "all") {
        $insights_articles.each(function(i, el) {
            el.classList.add("hide");
            el.classList.remove("hidden");

            if(el.classList.contains("preview")) {
                if(i < 4) {
                    el.classList.remove("hide");
                } else {
                    el.classList.add("hidden");
                }

                if(window.innerWidth < 600) {
                    const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
                    insightsNewAll.forEach((element, i) => element.classList.contains("preview") && i == 0 ? element.style.marginLeft =  "0.9375em" : element.style.marginLeft =  "5.7450628366%" );
                    insightsNewAll.forEach((element, i) => i < 4 ? element.classList.remove("hidden") : element.classList.add("hidden"));
                } else {
                    const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
                    insightsNewAll.forEach((element, i) => i % 4 === 0 ? element.style.marginLeft =  "0.9375em" : element.style.marginLeft =  "5.7450628366%" );
                    insightsNewAll.forEach((element, i) => (i % 3 === 0) && (i !== 0) ? element.style.marginRight =  "0" : element.style.marginRight =  "1.48%" );
                    insightsNewAll.forEach((element, i) => i < 4 ? element.classList.remove("hidden") : element.classList.add("hidden"));
                }
            } else {
                el.classList.remove("hide");

                if(window.innerWidth < 600) {
                    const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
                    insightsNewAll.forEach((element, i) => element.style.marginLeft = "0.93575em");
                } else {
                    const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
                    insightsNewAll.forEach((element, i) => (i % 3 === 0) && (i !== 0) ? element.style.marginRight =  "0" : element.style.marginRight =  "1.48%" );
                }
            }
        });
    } else {
        $insights_articles.each(function(i, el) {
            el.classList.add("hide");
            el.classList.remove("hidden");

            const $metaData = el.querySelector(".metadata").dataset.categories;

            if($metaData.indexOf($category) !== -1) {
                el.classList.remove("hide");
            }
            if(el.classList.contains("preview")) {
                if(window.innerWidth < 600) {
                    const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
                    insightsNewAll.forEach((element, i) => element.classList.contains("preview") && i == 0 ? element.style.marginLeft =  "0.9375em" : element.style.marginLeft =  "5.7450628366%" );
                    insightsNewAll.forEach((element, i) => i < 4 ? element.classList.remove("hidden") : element.classList.add("hidden"));
                } else {
                    const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
                    insightsNewAll.forEach((element, i) => i % 4 === 0 ? element.style.marginLeft =  "0.9375em" : element.style.marginLeft =  "5.7450628366%" );
                    insightsNewAll.forEach((element, i) => (i % 3 === 0) && (i !== 0) ? element.style.marginRight =  "0" : element.style.marginRight =  "1.48%" );
                    insightsNewAll.forEach((element, i) => i < 4 ? element.classList.remove("hidden") : element.classList.add("hidden"));
                }
            } else {
                if(window.innerWidth < 600) {
                    const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
                    insightsNewAll.forEach((element, i) => element.style.marginLeft = "0.93575em");
                } else {
                    const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
                    insightsNewAll.forEach((element, i) => (i % 3 === 0) && (i !== 0) ? element.style.marginRight =  "0" : element.style.marginRight =  "1.48%" );
                }
            }
        });
    } 
}); 

// Reset margin left
if($(".insights").length > 0) {
    $(window).resize(function() {
        if(window.innerWidth >= 600) {
            const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
            insightsNewAll.forEach(element => element.style.marginLeft = 0);
            insightsNewAll.forEach((element, i) => i % 3 === 0 && i !== 0 ? element.style.marginRight = 0 : element.style.marginRight = "1.48%");

            if(insightsNewAll[0].classList.contains("preview")) {
                
            } else {
                const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease");
                insightsNewAll.forEach((element, i) => element.style.marginLeft = "0");
            }
        } else {
            const insightsNewAll = document.querySelectorAll(".insights .posts-tease .tease:not(.hide)");
            insightsNewAll.forEach(element => element.style.marginRight = 0);

            if(insightsNewAll[0].classList.contains("preview")) {
                const insightsNewAllNotHide = document.querySelectorAll(".insights .posts-tease .tease");
                insightsNewAllNotHide.forEach(element => element.style.marginRight = 0);
                insightsNewAll.forEach((element, i) => element.classList.contains("preview") && i % 4 === 0 ? element.style.marginLeft =  "0.9375em" : element.style.marginLeft =  "5.7450628366%" );
            } else {
                insightsNewAll.forEach((element, i) => element.style.marginLeft = "0.9375em");
            }
        }
    });
}