var now = new Date();

let step1 = document.querySelectorAll('.step1')
let step2 = document.querySelectorAll('.step2')
let videoWrapper = document.querySelector('.videoWrapper')


function doDate()
{
    var str = "";


    var months = new Array("01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12");

    var now = new Date();

    str +=   now.getDate() + "." + months[now.getMonth()] + "." + now.getFullYear().toString().slice(-2) + " " + now.getHours() +":" + now.getMinutes() + ":" + now.getSeconds();
    document.getElementById("time-now").innerHTML = str;
}
if(videoWrapper) {
    setInterval(doDate, 500);
}

let timeNow = document.getElementById("time-now")
