const $header = $('header');
const $footer = $('footer');
const $menuElements = $('body, footer, header, .footer-overlay');
const headerHeight = $header.height();
let lastScrollTop = 0;

$(window).scroll(function(e) {
    let scrollTop = $(this).scrollTop();

    if ($header.hasClass('menu-active')) {
        return;
    }

    if (scrollTop === 0) {
        $header.removeClass('sticky show animate');
    } else if (scrollTop > headerHeight) {
        if (scrollTop < lastScrollTop) { // Scrolling up
            $header.addClass('sticky animate show')
        } else {
            $header.addClass('sticky').removeClass('show');
        }
    }

    lastScrollTop = scrollTop;
});

$('#menu-trigger button').click(e => {
    e.preventDefault();

    const isActive = $menuElements.hasClass('menu-active');

    if (isActive) {
        hideMenu();
    } else {
        showMenu();
    }

});

$('.menu-close').click(function(e) {
    e.preventDefault();

    hideMenu();
})

function showMenu() {
    $menuElements.addClass('menu-active');
    $header.removeClass('sticky show animate');

    setTimeout(() => {
        $footer.addClass('transition');
        $menuElements.addClass('menu-show');
    }, 10);
}

function hideMenu() {
    $menuElements.removeClass('menu-show');
    $('body, header, .footer-overlay').removeClass('menu-active');

    setTimeout(() => {
        $footer.removeClass('menu-active');
        $footer.removeClass('transition');
    }, 300);
}

$('.footer-overlay').click(function(e) {
    hideMenu();
});
