import Glide from '@glidejs/glide'


let glideQuote = document.querySelector('#glide-quotes');
let glideCases = document.querySelector('#glide-case');
let glideLogo = document.querySelector('#glide-logos');


if(glideQuote){

    var glideNavigation = document.querySelector('.bullets');
    if(glideNavigation){
        var items = glideNavigation.querySelectorAll('button');

        glideNavigation.querySelectorAll('button').forEach(function (link) {
            link.addEventListener('click', function (e) {
                items.forEach(function(el){
                    el.classList.remove('bullet-active')
                })
                var elem = this;
                this.classList.add('bullet-active')
                e.preventDefault()
                glide.go('=' + elem.getAttribute('data-id'));
            });
        });

    }






    const glide = new Glide('#glide-quotes', {
        type: 'carrousel',
        startAt: 0,
        perView: 1,
        animationDuration:0,
        swipeThreshold: false, dragThreshold: false

    })
    glide.mount()
    //adjusting the position of the controllers
    let controller = document.querySelector('#quote-controls')
    let controllerHeight = controller.offsetHeight;
    if(window.innerWidth > 1024){

        controller.style.transform = `translateY(${controllerHeight}px)`

    }
}





if(glideCases){
    let currentIndexCase = document.getElementById('current-slide-index-case')
    if(currentIndexCase) {
        const glideCase = new Glide('#glide-case', {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            animationDuration:0,

        })
        glideCase.on(['mount.after', 'run'], () =>{
            currentIndexCase.innerHTML = `${glideCase.index +1}`
        })
        glideCase.mount()
    }
}

if(glideLogo) {
    if (document.getElementById('glide-logos')) {
        const glideLogos = new Glide('#glide-logos', {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            autoplay: 3000,
            animationDuration: 1000,
        })
        glideLogos.mount();
    }
}

