const $expand = $(".text-content .expand");

if($expand.length !== 0) {
    $('.text-content .expand h3 > a').click(function(e) {
        e.preventDefault();
        $(this).parents('.expand').toggleClass('active');
    });


}
