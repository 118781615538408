
const $modulesLi = $('.block-modules').find('.modules li');

$modulesLi.click(function(e) {
    const $target = $(e.target);
    const $li = e.target.tagName === 'LI' ? $target : $target.parents('li');

    $modulesLi.removeClass('open');

    $li.addClass('open');

    const $img = $target.parents('.block-modules').find('.modules > div > figure img');
    const $video = $target.parents('.block-modules').find('.modules > div > figure video');
    const imgSrc = $li.data('imageSrc');
    const videoSrc = $li.data('videoSrc');

    $img.attr('src', imgSrc);
    $video.attr('src', videoSrc);

    if (videoSrc) {
        $video.show();
        $img.hide();
    } else if (imgSrc) {
        $img.show();
        $video.hide();
    } else {
        $img.hide();
        $video.hide();
    }
});
